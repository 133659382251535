











































































import { Component, Mixins } from 'vue-property-decorator'

import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import ArrowDown16 from '@carbon/icons-vue/es/arrow--down/16'
import ArrowUp16 from '@carbon/icons-vue/es/arrow--up/16'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Ticket16 from '@carbon/icons-vue/es/ticket/16'
import isEmpty from 'lodash/isEmpty'

import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import GenericModalForm from '@/partials/modals/generic/form.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import EventUserDropdown from '@/partials/forms/Events/EventUserDropdown.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'
import RichTextDisplay from '@/partials/components/RichTextDisplay.vue'
import snakeCaseKeys from 'snakecase-keys'
import TablePagination from '@/partials/tables/Pagination.vue'

@Component({
  components: {
    TablePagination,
    RichTextDisplay,
    ArrowDown16,
    ArrowUp16,
    EventUserDropdown,
    PasswordConfirmField,
    GenericModalDelete,
    GenericModalForm,
    TrashCan16,
    Ticket16,
    Edit16
  }
})
export default class EventScheduleQuestions extends Mixins(FlashMessageMixin, PermissionsMixin) {
  // @Prop({ required: true, type: String }) eventId!: string
  eventId = this.$route.params.id
  eventScheduleQuestions = []
  scheduleQuestionsTotal = 0
  deleteModalIsOpened = false
  selectedScheduleQuestion = {
    id: '',
    datetime: '',
    title: '',
    description: ''
  }

  created () {
    this.fetch()
  }

  fetch (page = 1, perPage = 200) {
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get(`/event/${this.eventId}/schedule-questions`, {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.eventScheduleQuestions = camelCaseKeys(response.data.data, { deep: true })
        this.scheduleQuestionsTotal = response.data.total
      })
  }

  tagKind (status: 'received' | 'accepted' | 'answered' | 'rejected') {
    const dict = {
      received: ['warm-gray', 'Recebida'],
      accepted: ['cyan', 'Aguardando resposta'],
      answered: ['green', 'Respondida'],
      rejected: ['red', 'Rejeitada']
    }

    return dict[status]
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'A Pergunta foi removida com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  get deleteRequestBody () {
    return { eventUsers: [isEmpty(this.selectedScheduleQuestion) ? '' : this.selectedScheduleQuestion.id] }
  }

  onPageChange (pagination: Pagination) {
    this.fetch(pagination.page, pagination.length)
  }

  get deleteUrl () {
    return isEmpty(this.selectedScheduleQuestion) ? '' : `/event/${this.eventId}/schedule-questions/${this.selectedScheduleQuestion.id}`
  }
}
